<template>
  <div>

    <b-table
      small
      :fields="fields"
      :items="orders"
      responsive="sm"
    >

      <!-- A custom formatted column -->
      <template #cell(plan)="data">
        {{ data.value.name }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(ss)="data">
        {{ bytesToSize(data.value.u + data.value.d) }}/{{
          bytesToSize(data.value.transfer_enable)
        }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(ChargeTypeID)="data">
        {{
          getType(
            data.value
          )
        }}
      </template>

      <template #cell(InitTime)="data">
        {{ convertTimestamp(data.value) }}
      </template>

      <template #cell(FinishTime)="data">
        {{ convertTimestamp(data.value) }}
      </template>

      <template #cell(status)="data">
        <b-badge
          pill
          :variant="getStatusVariant(data.value)"
        >
          {{ fixStatus(data.value) }}
        </b-badge>
      </template>

    </b-table>

    <div class="block">
      <b-pagination
        v-model="arg.page"
        :per-page="arg.pageSize"
        hide-goto-end-buttons
        :total-rows="total"
        @click.native="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import { bytesToSize } from '@/utils/flow'
import { convertTimestamp } from '@/utils/format-date'
import { notify, throwError } from '@/utils/utils'
import Ripple from 'vue-ripple-directive'
import useHttp from '@/http/useHttp'
import {
  BTable, BBadge, BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BBadge,
    BPagination,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        // A column that needs custom formatting
        { key: 'ID', label: 'InvoiceID', sortable: true },
        // A regular column
        { key: 'ChargeTypeID', label: '付款方式' },
        // A regular column
        { key: 'Money', label: '金额' },
        // A virtual column made up from two fields
        { key: 'Status', label: 'Status' },
        { key: 'InitTime', label: '创建', sortable: true },
        { key: 'FinishTime', label: '完成', sortable: true },
      ],
      orders: [],
      total: 14,
      arg: {
        pageSize: 10,
        page: 1,
      },
      error: {},
    }
  },
  created() {
    this.refreshData(this.arg)
  },
  methods: {
    getStatusVariant(s) {
      if (s === 0) {
        return 'info'
      }
      return 'success'
    },
    fixStatus(s) {
      if (s === 0) {
        return '待支付'
      }
      return '支付成功'
    },
    getType(s) {
      if (s === 0) {
        return '-'
      }
      return '支付宝'
    },
    handleSizeChange(size) {
      this.arg.pageSize = size
      this.refreshData()
    },
    handleCurrentChange(currentPage) {
      this.arg.page = currentPage
      this.refreshData()
    },
    onPageChange() {
      this.refreshData(this.arg)
    },
    viewOrder(id) {
      this.$router.push({ name: 'shadowx-order-info', params: { id } })
    },
    refreshData(arg) {
      useHttp.getPayOrders({
        params: arg,
      }).then(res => {
        this.total = res.data.data.count
        this.orders = res.data.data.data
        // console.log(this.total)
      }).catch(error => {
        throwError(error)
        notify('Error', '获取充值记录失败', 'danger')
      })
    },
    bytesToSize,
    convertTimestamp,
  },
}
</script>
